<template>
    <CModalExtended
    :title="titulo"
    color="dark"
    size="lg"
    :show.sync="modalActive"
    :close-on-backdrop="false"
    >
      <CRow>
        <CCol sm="12">
          <CRow class="mb-3">
            <CCol sm="4" class="text-right mt-2 pr-1">
              <label><b class="text-danger">*</b> {{ $t('label.newShippingLine') }} </label>
            </CCol>
            <CCol sm="6">
              <v-select 
                :options="shippingLineOptions"
                label="ShippingLineName"
                :placeholder="$t('label.select')"
                v-model="ShippingLine"
                :class="isShippingLineValido(ShippingLineVal)" 
              />
            </CCol>
            <CCol sm="2">
              <CButton color="add" :disabled="isSubmit" size="sm" class="mr-1"  
                @click="editMode?statusConfirmation():submit()" 
                v-c-tooltip="{
                    content: $t('label.add'),
                    placement: 'top-end'
                }"
                >
                <CIcon name="checkAlt"/>
              </CButton>
              <CButton color="wipe" :disabled="isSubmit" size="sm" class="mr-1"  
                @click="resetForm()"  
                v-c-tooltip="{
                    content: $t('label.clearFields'),
                    placement: 'top-end'
                }"
                >
                <CIcon name="cil-brush-alt"/>
              </CButton>
            </CCol>
          </CRow>
          <CRow>
            <CCol sm="4" class="text-right mt-2 pr-1">
              <label><b class="text-danger"> * </b> {{ $t('label.code') }} </label>
            </CCol>
            <CCol sm="6">
              <CInput
                :placeholder="$t('label.code')"
                v-model="$v.PortCode.$model"
                v-uppercase
                :invalid-feedback="errorMessage($v.PortCode)" 
                :is-valid="hasError($v.PortCode)"
                maxlength="3"
              >
              </CInput>
            </CCol>
            <CCol sm="2">
              
            </CCol>
          </CRow>
          <CCollapse :show="editMode">
            <CRow >
              <CCol  sm="4" class="text-right mt-2 pr-1">
                <label>{{ $t('label.status') }} </label>
              </CCol>
              <CCol sm="6"> 
                <CSelect
                :value.sync="Status"
                :is-valid="statusSelectColor"
                :options="statusOptions"
                />              
              </CCol>
              <CCol sm="2">
              </CCol>
            </CRow> 
          </CCollapse>              
        </CCol>
        <CCol sm="12">
          <dataTableExtended
            class="align-center-row-datatable"
            hover
            sorter
            smail
            column-filter
            :table-filter="tableText.tableFilterText"
            :items-per-page-select="tableText.itemsPerPageText"
            :noItemsView="tableText.noItemsViewText"
            :items="formatedItems"
            :fields="fields"
            :items-per-page="5"
            :loading="isLoading"
            pagination
             
          >
            <template #RowNumber="{ item }">
              <td class="center-cell">
                {{ item.RowNumber }}
              </td>
            </template>
            <template #loading>  <loading/> </template>

            <template #Status="{item}">
              <td class="center-cell">
                <CBadge :color="getBadge(item.Status)">
                  {{ $t('label.'+item.Status) }}
                </CBadge>
              </td>
            </template>

            <template #Details="{ item }">
              <td class="center-cell">
                    <CButton
                      color="edit"
                      size="sm"
                      v-c-tooltip="{
                        content: $t('label.edit')+' '+$t('label.code'),
                        placement: 'top-end'
                      }"
                      @click="toggleEdit(item)"
                    >
                      <CIcon name="pencil" />
                    </CButton>
              </td>
            </template>
          </dataTableExtended>
        </CCol>
      </CRow>
      <template #footer>
        <!--CButton
          color="add"
          :disabled="isSubmit"
          class="d-flex align-items-center mr-2"
          @click="editMode?statusConfirmation():submit()"
          >
          <CIcon name="checkAlt"/><span class="ml-1">{{$t('button.accept')}}</span>
        </CButton-->
        <CButton
          color="wipe"
          :disabled="isSubmit"
          class="d-flex align-items-center"
          @click.stop="toggle(false)"
          >
          <CIcon name="x"/><span class="ml-1">{{$t('button.exit')}}</span>
        </CButton>
      </template>
    </CModalExtended>
</template>

<script>
  import { DateFormater, tableTextHelpers } from '@/_helpers/funciones';
  import UpperCase  from '@/_validations/uppercase-directive';
  import CodesFormValidations from '@/_validations/puerto/codesFormValidations';

  import GeneralMixin from '@/_mixins/general';
  import ModalMixin from '@/_mixins/modal';
  //Data
  function data() {
    return {
      PortShippingLineId: '',
      portName: '',
      portId: '',   
      PortCode: '',
      ShippingLine: null,
      Status: 1,
      oldStatus: 1,
      titulo: '',
      items: [],
      shippingLines: [],  
      modalActive: this.modal,
      isSubmit: false,    
      editMode: false,
      ShippingLineVal: false, 
      isLoading: false,
    }
  }

//methods
  function getShippingLine() {
  // this.isLoading = true;
    this.$http.get("shipping-agency-list", { Filter: 'ACTIVO' })
    .then(response => {
      this.shippingLines = response.data.data;
    }).catch( err => {
      this.$notify({
        group: 'container',
        title: '¡Error!',
        text: err,
        type: "error"
      });
    }).then(() => {
    // this.isLoading = false;
    });
  }

  function getPortShippingLine(idPort) {
    this.isLoading = true;
    this.$http.get('PortShippingLine-list-by-port',{ PortId: idPort} )
    .then(response => {
      this.items = response.data.data;  
    }).catch(err => {
      this.$notify({
        group: 'container',
        title: '¡Error!',
        text: err,
        type: "error"
      });
    }).then(() => {
    this.isLoading = false;
    });
  }

  function submit() {
    try { 
      this.isLoading = true;
      this.isSubmit = true;
      this.$v.$touch();
      this.ShippingLineVal = true;

      if (this.$v.$error) {
        this.isSubmit = false;
        this.isLoading = false;
        throw this.$t('label.errorsPleaseCheck');
      }

      let metodo = this.editMode ? 'PUT':'POST';
      let ruta = this.editMode ? 'PortShippingLine-update' : 'PortShippingLine-insert'; 
      let PortShippingLineJson = this.editMode
        ? {
            PortId: this.portId,
            PortShippingLineId: this.PortShippingLineId, //id de la sigla-puerto 
            ShippingLineId: this.ShippingLine.ShippingLineId, //id linea
            PortCode: this.PortCode,                          //siglas
            Status: this.Status,           
          }
        : {
            PortId: this.portId,
            ShippingLineId: this.ShippingLine.ShippingLineId,
            PortCode: this.PortCode,
          };
      this.$http.ejecutar(metodo, ruta, PortShippingLineJson, { root: 'PortShippingLineJson' })
      .then(response => {
        this.$notify({
          group: 'container',
          title: '¡Exito!',
          text: response.data.data[0].Response,
          type: "success"
        });
        this.getPortShippingLine(this.portId);// lista de siglas por puerto
        this.resetForm();
      }).catch(err => {
        this.$notify({
          group: 'container',
          title: '¡Error!',
          text: err,
          type: "error"
        });
      }).then(() => {
        this.isSubmit = false;
        this.isLoading = false;
      });
    } catch (e) {
      this.$notify({  
        group: 'container',
        title: '¡Error!',
        text: e,
        type: "error" });
    }
  }

  function setPuertoData(newPuerto) {
    this.portId = newPuerto.PortId;
    this.portName = newPuerto.PortName;
    this.titulo = this.$t('label.portCodes')+`: ${newPuerto.PortName}`;
    this.getPortShippingLine(this.portId);
  }

  function toggle(newVal) {
    if(newVal){
    }else
      {
        this.portName= '',
        this.portId= '',
        this.titulo = '';
        this.resetForm();
      }
    this.modalActive = newVal;
  }
  
  function resetForm() {
    this.PortCode = '';
    this.ShippingLine = '';
    this.Status = 1;
    this.oldStatus = 1;
    this.PortShippingLineId='',
    this.editMode = false;
    this.ShippingLineVal = false;
    this.$v.$reset();
  }

  function statusConfirmation(){
    if(this.Status === this.oldStatus)
      this.submit();
    else{
      this.$swal.fire({
      text: `${this.$t('label.changeStatusQuestion')} ${this.$t('label.code')} ${this.PortCode} ${this.$t('label.port')} ${this.portName}?`,
      icon: "warning",
      showCancelButton: true,
      confirmButtonColor: '#42AA91',
      cancelButtonColor: '#E1373F',
      confirmButtonText: this.$t('button.confirm'),
      cancelButtonText: this.$t('button.cancel'),
      }).then((result) => {
        if (result.isConfirmed) {
          this.submit();
        }
      });
    }
  }

  function isShippingLineValido(ShippingLineVal) {
    if(this.ShippingLineVal === true){ 
      return (this.ShippingLine == null || this.ShippingLine == "") ? 'no-valido' : 'valido';
    }
  }

  function toggleEdit(code) {
    this.setForm(code);
    this.editMode = true;
  }

  function setForm(oldCode) {
    this.ShippingLineVal = oldCode.ShippingLineVal; 
    this.PortShippingLineId = oldCode.PortShippingLineId;
    this.ShippingLine = Object.assign({}, { 
      value: oldCode.ShippingLineId,
      label: oldCode.ShippingLineName,
      ShippingLineId: oldCode.ShippingLineId,
      ShippingLineName: oldCode.ShippingLineName
    });
    this.PortCode = oldCode.PortCode;
    this.Status = oldCode.FgActPortShippingLine?1:0;
    this.oldStatus = oldCode.FgActPortShippingLine?1:0;
    this.ShippingLineVal = true;
    this.$v.$touch();
  }
//computed
  function shippingLineOptions() {
    return this.shippingLines.map((item) => Object.assign({}, item, {
      value: item.ShippingLineId,
      label: item.ShippingLineName
    }));
  }

  function statusSelectColor(){
    return this.Status === 1;
  }

  function formatedItems() {
    return this.items.map((item) => Object.assign({}, item, {
      RowNumber: item.Nro,
      FormatedDate: item.TransaRegDate ? DateFormater.formatOnlyDateWithSlash(item.TransaRegDate) : '',
      _classes: item.Status === 'ACTIVO'? '': 'table-danger'
    }));
  }

  function fields() {
    return[
      { 
        key: 'RowNumber', 
        label: '#',
        _style: 'width:1%; text-align:center;', filter: false,
      },
      { key: 'PortCode', label: this.$t('label.code'), _style: 'width:20%;',_classes:'text-uppercase text-center'},
      { key: 'ShippingLineName', label: this.$t('label.newShippingLine'), _style: 'width:35%;',_classes:'text-uppercase text-left'},
      { key: 'TransaLogin', label: this.$t('label.user'), _style: 'width:10%;',_classes:'text-uppercase'},
      { key: 'FormatedDate', label: this.$t('label.date'), _style: 'width:12%;', _classes:'text-center'}, 
      { key: 'Status', label: this.$t('label.status'), _style: 'width:10%;', _classes:'text-center'},
      { 
        key: 'Details', 
        label: '', 
        _style: 'width:1% text-align:right; min-width:50px;', 
        sorter: false, 
        filter: false, 
        _classes:'text-center'
      }
    ]
  }

  export default {
    name: 'add-codes-modal',
    data,
    validations: CodesFormValidations,
    directives: UpperCase,
    mixins: [GeneralMixin, ModalMixin],
    props: {
      modal: Boolean,
      puertoData: {
        type: Object,
        default: () => { return {} }
      }
    },
    methods: {
      getShippingLine,
      getPortShippingLine,
      submit,
      setPuertoData,
      toggle,
      resetForm,
      statusConfirmation,
      isShippingLineValido,
      statusSelectColor,
      toggleEdit,
      setForm,
    },
    computed: {
      shippingLineOptions,
      formatedItems,
      fields,
    },
    watch: {
      modal: function(val) {
        this.toggle(val);
        this.getShippingLine();
      },
      modalActive: function(val) {
        this.$emit('update:modal', val);
      },
      puertoData: function(newPuerto) {
        this.setPuertoData(newPuerto);
      },
    }
  }
</script>
<style lang="scss">
  .center-cell {
    text-align: center;
  }
  .table-index {
    table {
      td {
        vertical-align: middle !important;
      }
    }
  }
</style>